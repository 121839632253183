export default {
  screens: {
    menu: {
      viewOrderCtaLabel: 'Peržiūrėti užsakymą',
      completedOrdersSmallBtn: 'Įvykdyti',
      completedOrdersLargeBtn: 'Peržiūrėti įvykdytus užsakymus',
      activeOrdersSmallBtn: 'Aktyvūs',
      activeOrdersLargeBtn: 'Peržiūrėti aktyvius užsakymus',
      singleActiveOrderSmallBtn: 'Aktyvus',
      singleActiveOrderLargeBtn: 'Peržiūrėti aktyvų užsakymą',
      quickAccessTitle: 'Rask, ko ieškai',
      ordersDisabledCtaLabel: 'Užsakymai laikinai nepriimami',
      ordersDisabledShortCtaLabel: 'Užsakymai nepriimami',
      payBillCta: 'Apmokėti sąskaitą',
    },
    order: {
      headerTitle: 'Užsakymo suvestinė',
      headerSubtitle: 'Užsakymas prie {tableName} stalo',
      headerSubtitleWithoutTable: '{tableName}',
      summaryTitle: 'Jūsų stalo sąskaita',
      emptyStateTitle: 'Stalo užsakymas tuščias',
      emptyStateSubtitle:
        'Kol kas padavėjas nesuvedė užsakymo į kasą. Čia matysite savo užsakymą ir galėsite jį apmokėti.',
      orderListTitle: 'Užsakymas',
      orderListLeftUnpaidProductTitle: 'Likę neapmokėti produktai',
      orderListPaidProductTitle: 'Apmokėti produktai',
      pickItemsTitle: 'Produktai apmokėjimui',
      pickItemsSubtitle: 'Pasirinkite, kuriuos produktus norite apmokėti',
      orderListTotal: 'Užsakymo suma',
      continueToPayCta: 'Eiti į apmokėjimą',
      backCta: 'Grįžti',
      orderStatusReceived: 'Užsakymas #{orderId} gautas',
      orderStatusPendingConfirmation: 'Užsakymas #{orderId} gautas...',
      orderStatusSeen: 'Užsakymas #{orderId} peržiūrimas...',
      orderStatusPreparing: 'Užsakymas #{orderId} ruošiamas...',
      orderStatusWaitingOthers: 'Laukiame kitų svečių ({ready}/{total})',
      orderStatusWaitingOthersDescription:
        'Jūsų užsakymą ir apmokėjimą gavome. Laukiame, kol kiti prie stalo esantys svečiai pateiks savo užsakymus.',
      orderStatusWaitingOthersDisclaimer:
        'Užsakymą automatiškai išsiųsime už {time}.',
      orderStatusSendingToPOSTitle: 'Užsakymas keliauja į kasą',
      orderStatusSendingToPOSDescription: 'Luktelkite. Užsakymas apdorojamas.',
      orderStatusDelivered: 'Užsakymas #{orderId} pristatytas!',
      orderStatusPartiallyDelivered: 'Dalis užsakymo #{orderId} pristatyta',
      pickup: {
        orderStatusPendingConfirmation: 'Užsakymas #{orderId} gautas...',
        orderStatusSeen: 'Užsakymas #{orderId} peržiūrimas...',
        orderStatusPreparing: 'Užsakymas #{orderId} ruošiamas...',
        orderStatusPartiallyDelivered:
          'Dalis užsakymo #{orderId} paruošta atsiėmimui',
        orderStatusDelivered: 'Užsakymas #{orderId} paruoštas atsiimti!',
      },
      orderStatusFailed: 'Užsakymas buvo atmestas',
      orderFailedDescription:
        'Atsiprašome, užsakymo priimti nepavyko. Pinigai už šį užsakymą bus grąžinti į jūsų banko sąskaitą. Norėdami užsisakyti, kreipkitės į restorano darbuotoją.',
      orderStatusRejected: 'Užsakymas atšauktas',
      orderRejectedDescription:
        'Jūsų užsakymą gavome, tačiau negalime jo įvykdyti. Norėdami išsiaiškinti priežastį, susisiekite žemiau nurodytu telefonu.',
      orderReceivedDescription:
        'Gavome jūsų apmokėjimą. Užsakymas bus pristatytas netrukus.',
      orderReceivedPickupDescription:
        'Gavome jūsų apmokejimą. Užsakymas bus paruoštas atsiimti netrukus.',
      orderReceivedPayAtRestaurantDescription:
        'Jūsų užsakymas gaminamas ir netrukus bus pateiktas. Apsilankymo pabaigoje nepamirškite atsiskaityti pas padavėją.',
      deliveryTime: 'Pristatymo laikas',
      deliveryTimeWillBeUpdated: 'Pristatymo laikas tuoj pat bus patikslintas',
      pickupTimeWillBeUpdated: 'Atsiėmimo laikas tuoj pat bus patikslintas',
      pickUpTime: 'Atsiėmimo laikas',
      totalPaid: 'Iš viso sumokėta',
      total: 'Iš viso',
      leftToPay: 'Liko mokėti',
      tips: 'Arbatpinigiai',
      transactionFee: 'Paslaugos mokestis',
      smallOrderFee: 'Mažo užsakymo mokestis',
      paymentType: 'Mokėjimo būdas',
      disclaimerTextPending:
        'Atsipalaiduokite ir mėgaukitės renginiu! Informuosime, kai restoranas pradės ruošti užsakymą.',
      disclaimerTextActive:
        'Atsipalaiduokite ir mėgaukitės renginiu! Informuosime, kai restoranas pristatys tavo užsakymą.',
      disclaimerTextPartial:
        'Atsipalaiduokite ir mėgaukitės renginiu! Informuosime, kai restoranas pristatys likusią dalį tavo užsakymo.',
      disclaimerTextClosed:
        'Užsakymas pristatytas į Jūsų ložę. Paimkite užsakymą!',
      leaveAReviewHeader: 'Įvertinkite užsakymą',
      turnOnNotificationsHeader: 'Sekite gyvai savo užsakymą',
      turnOnNotificationsBody:
        'Įjunkite pranešimus ir gaukite informaciją apie jūsų užsakymo eigą.',
      turnOnSMSNotificationsBody:
        'Įjunkite SMS pranešimus ir gaukite informaciją apie jūsų užsakymo eigą.',
      turnOnNotificationsCta: 'Įjungti pranešimus',
      turnOnSMSNotificationsCta: 'Įjungti SMS pranešimus',
      notificationsModalTitle: 'Sekite savo užsakymą gyvai',
      notificationsModalSubtitle:
        'Įjunkite pranešimus ir gaukite informaciją apie jūsų užsakymo eigą.',
      notificationsModalSubtitleSMS:
        'Įveskite savo tel. nr. ir gaukite SMS pranešimus apie jūsų užsakymo eigą.',
      notificationsModalBodySMS:
        'Jokia kita su užsakymu nesusijusi informacija siunčiama nebus. Tai jums nieko nekainuos.',
      orderInformationTitle: 'Užsakymo informacija',
      orderNumberText: 'Užsakymo numeris: {orderNumber}',
      smsDisclaimer:
        'Kai užsakymas bus paruoštas atsiimti, informuosime jus SMS žinute.',
      orderIdText: 'Užsakymo ID: {orderId}',
      orderTimeText: 'Laikas: {time}',
      orderProviderText: 'Platformą administruoja greet, support@greet.menu',
      restaurantInformationTitle: 'Restorano informacija',
      contactHotel: 'Susisiekite su registratūra',
      contactRestaurant: 'Susisiekite su restoranu',
      orderTotalAmount: 'Užsakymo suma',
      someItemsSelectedToast: {
        title: 'Negalima apmokėti visos sąskaitos ⚠️',
        description:
          'Dalį produktų jau pasirinko jūsų stalo draugai, spauskite „Pasidalinti“ apmokėti likusiems.',
      },
      foodieLabel: 'Gurmanas #{number}',
      foodie: {
        1: 'Skanautojas',
        2: 'Valgytojas',
        3: 'Maistautojas',
        4: 'Gastronomas',
        5: 'Kulinaras',
        6: 'Smaližius',
        7: 'Gurmanas',
        8: 'Ragautojas',
      },
      sentToPosCta: 'Nelaukti ir siųsti užsakymą',
    },
    payment: {
      headerTitle: 'Apmokėjimas',
      headerSubtitle: 'Staliukas {tableName}',
      headerSubtitleWithoutTable: '{tableName}',
      summaryTitle: 'Sąskaita',
      summaryAmountLabel: 'Sąskaitos suma',
      payGoSummaryAmountLabel: 'Bendra sąskaitos suma',
      summaryAmountBeingPaidLabel: 'Jūsų sąskaitos suma',
      summaryFeeLabel: 'Paslaugos mokestis',
      summarySmallOrderFeeLabel: 'Mažo užsakymo mokestis',
      summaryLoyaltyDiscountLabel: 'Nuolaidų kortelė',
      summaryTipsLabel: 'Arbatpinigiai',
      summaryTotalLabel: 'Užsakymo suma',
      paymentMethods: 'Apmokėjimas',
      payCtaLabel: 'Apmokėti',
      termsOfService: 'Apmokėdami patvirtinate, kad susipažinote su',
      termsOfServiceLink: 'paslaugų teikimo sąlygomis',
      privacyPolicyLink: 'privatumo politika',
      paymentInProgress: 'Apmokėjimas vykdomas',
      paymentSuccessful: 'Apmokėjimas gautas',
      paymentFailed: 'Apmokėjimas nepavyko',
      discountLabel: 'Nuolaida',
      paymentLabel: 'Mokėjimas',
      payAtRestaurantCouponText: 'Prisitaikys apmokant',
      error: {
        partAlreadyPaid: {
          title: 'Apmokėjimas negalimas',
          description:
            'Dalis užsakymo buvo apmokėta per padavėją. Dėl likusio mokėjimo kreipkitės į restorano darbuotoją.',
        },
        precheckPrinted: {
          title: 'Apmokėjimas negalimas',
          description:
            'Pre-čekis buvo atspausdintas. Dėl likusio mokėjimo kreipkitės į restorano darbuotoją.',
        },
        paymentUnavailableGeneric: {
          title: 'Apmokėjimas negalimas',
          description:
            'Dėl sąskaitos apmokėjimo kreipkitės į restorano darbuotoją.',
        },
        generic: {
          title: 'Uuups! Kažkas atsitiko',
          description: 'Įvyko klaida. Bandykite dar kartą.',
        },
      },
      paymentSessionExpired:
        'Moekjimo sesija baigėsi norėdami tęsti apmokėjimą, pradėkite iš naujo.',
    },
    paymentSuccess: {
      successMessage:
        'Mokėjimas atliktas sėkmingai! Padavėjas yra informuotas apie jūsų apmokėjimą.',
      headerTitle: 'Mokėjimas patvirtintas',
      amountPaid: 'Jūs sumokėjote',
      leftToPay: 'Liko sumokėti',
      summaryTitle: 'Apmokėti produktai',
      goBackCtaLabel: 'Grįžti į užsakymą',
      failed: {
        title: 'Mokėjimas nepavyko',
        description:
          'Patvirtinti mokėjimo nepavyko. Pabandykite dar kartą arba pasirinkite kitą mokėjimo būdą.',
      },
    },
    paymentHistory: {
      navTitle: 'Mokėjimų istorija',
      empty: {
        title: 'Mokėjimų nėra',
        content:
          'Čia matysite savo apmokėjimus bei visą su jais susijusią informaciją.',
      },
    },
    placeOrder: {
      headerTitle: 'Užsakymo suvestinė',
      headerSubtitle: 'Užsakymas prie {tableName} staliuko',
      orderCardTitle: 'Užsakymas',
      headerSubtitleWithoutTable: '{tableName}',
      userCommentTitle: 'Palikite komentarą virtuvei',
      userCommentSubtitle:
        'Galbūt esate alergiškas ar turite pageidavimų? Komentare nurodykite, kuriam patiekalui reikėtų papildomų pakeitimų.',
      userCommentPlaceholder: 'pvz., į burgerį nedėti svogūnų',
      emptyStateTitle: 'Užsakymas tuščias',
      emptyStateSubtitle:
        'Kol kas nieko nepridėjote prie užsakymo. Grįžkite į meniu ir išsirinkite norimą patiekalą ar gėrimą.',
      cartDeleteTitle: 'Išvalyti užsakymą',
      cartDeleteSubtitle:
        'Ar tikrai norite pašalinti visus užsakyme esančius produktus?',
      cartDeleteCta: 'Išvalyti',
      tipsTitle: 'Palikti arbatpinigių?',
      tipsCustomAmountChipLabel: 'Kita suma',
      tipsSubtitle:
        'Padavėjas, aptarnaujantis jūsų stalą, gaus 100 % pasirinktos sumos.',
      tipsSheetTitle: 'Įveskite sumą',
      tipsSheetSubtitle: 'Užsakymo suma {subtotalPrice}',
      scrollToBottomLabel: 'Norėdami užsakyti, slinkite žemyn',
      continueToPaymentCta: 'Eiti į apmokėjimą',
      nextCtaLabel: 'Toliau',
      placeOrderCta: 'Pateikti užsakymą',
      registerToSaveContent:
        'Pasinaudokite {restaurantName} lojalumo programos nuolaidomis.',
    },
    orders: {
      headerTitle: 'Mano užsakymai',
      ongoingOrders: 'Vykdomi užsakymai',
      completedOrders: 'Įvykdyti užsakymai',
      statusPreparing: 'Gaminama',
      statusClosed: 'Įvykdyta',
      statusWaitingConfirmation: 'Laukiama patvirtinimo',
      statusFailed: 'Klaida',
      statusRejected: 'Atšauktas',
      emptyStateTitle: 'Užsakymų nėra',
      emptyStateSubtitle:
        'Čia matysite visus savo užsakymus. Grįžkite į meniu ir užsisakykite.',
    },
    search: {
      noResultsTitle: 'Nieko neradome',
      noResultsSubtitle: 'Pabandykite ieškoti ko nors kito',
      searchPlaceholder: 'Ieškoti meniu',
    },
    extraDetails: {
      headerTitle: 'Papildoma informacija',
      formTitle: 'Papildoma informacija',
      disclaimerTitle: 'Mokėkite pasibaigus viešnagei',
      disclaimerSubtitle:
        'Nesukite galvos dėl apmokėjimo. Užsakymo suma bus įskaičiuota į Jūsų kambario sąskaitą, kurią apmokėsite pasibaigus viešnagei.',
      subtotalTitle: 'Sąskaita',
      ctaLabel: 'Patvirtinti užsakymą',
      payCtaLabel: 'Mokėti',
      loadingBackdropText: 'Užsakymas vykdomas',
      successBackdropText: 'Užsakymas vykdomas',
      failedBackdropText: 'Užsakymas nepavyko',
    },
    payGoOrder: {
      payAllCta: 'Apmokėti visą',
      payForProductsCta: 'Pasidalinti',
      backCta: 'Grįžti',
      refreshCta: 'Atnaujinti',
      allPaidMessage:
        'Sąskaita apmokėta sėkmingai! Padavėjas yra informuotas apie jūsų mokėjimą.',
    },
    tipPersonnelMember: {
      empty: {
        title: 'Padavėjo paskyra neaktyvi',
        subtitle:
          'Padavėjas, kuriam ruošiatės palikti arbatpinigių, dar neaktyvavo savo Greet paskyros. Paskatinkite jį tai padaryti!',
        cta: 'Perkrauti puslapį',
      },
      tipTitle: 'Kiek arbatpinigių norėtumėte palikti?',
      tipSubtitle:
        'Padavėjas, aptarnaujantis jūsų stalą, gaus 100 % pasirinktos sumos.',
      title: 'Arbatpinigiai',
      payCta: 'Apmokėti',
      transactionFee: 'Paslaugos mokestis',
      total: 'Iš viso',
      subtotal: '{name} gaus',
      personnelPicker: {
        title: 'Pasirinkite',
        cta: 'Pasirinkti kitą',
      },
    },
    tipSuccess: {
      title: 'gavo tavo {amount} arbatpinigių!',
      viewThankYouCta: 'Peržiūrėkite padėkos žinutę',
      thankYouTitle: 'Speciali žinutė tau',
      backCta: 'Grįžti atgal',
      failed: {
        title: 'Uuups! Kažkas atsitiko',
        subtitle:
          'Mokėjimas vis dar neįvykdytas. Pabandykite perkrauti puslapį.',
      },
      followGreet: 'Stebėkite, kaip sekasi Greet:',
    },
    register: {
      navTitle: 'Registracija',
      headerTitle: 'Susikurkite ',
      greetWallet: 'Greet Wallet',
      headerSubtitle: 'Visos restoranų lojalumo programos vienoje vietoje.',
      login: {
        meetGreetTitle: 'Susipažinkite su',
        meetGreetSubtitle:
          'Lengviausiu užsakymo ir atsiskaitymo būdu restorane.',
        learnMoreCta: 'Sužinoti daugiau',
        createAccTitle: 'Sukūrę paskyrą mėgaukitės:',
        createAccBenefit1: 'specialiais restoranų pasiūlymais;',
        createAccBenefit2: 'asmeninėmis nuolaidomis;',
        createAccBenefit3:
          'skaitmenine nuolaidų kortele – daugiau jokių namuose paliktų kortelių!',
      },
    },
    profileDetails: {
      headerTitle: 'Asmeninė informacija',
      nameFieldLabel: 'Vardas',
      emailFieldLabel: 'El. paštas',
      joinedFieldLabel: 'Prisijungė',
      disclaimer:
        'Dėl visų su duomenų subjektų teisių įgyvendinimu susijusių klausimų, įskaitant duomenų ištrynimą, atnaujinimą, perkėlimą, sutikimų suteikimą bei atšaukimą ir kt., kreipkitės',
      contactUs: 'privacy@greet.menu',
    },
    profile: {
      login: {
        title: 'Prisijunkite per socialinius tinklus',
        subtitle:
          'Sukurkite paskyrą Greet sistemoje ir mėgaukitės restoranų pasiūlymais.',
      },
      headerTitle: 'Mano profilis',
      discountCardTitle: 'Aktyvios nuolaidų kortelės',
      profileDetailsCta: 'Asmeninė informacija',
      signOutCta: 'Atsijungti',
    },
    signUp: {
      headerTitle: 'Užsiregistruoti',
    },
    signIn: {
      headerTitle: 'Prisijungti',
      signInCta: 'Prisijungti',
    },
    selectScreen: {
      tableName: 'Staliukas {tableNumber}',
      tableNameWithoutTable: '{tableNumber}',
      viewMenuCta: 'Peržiūrėti meniu',
      viewMenuAndOrderCta: 'Peržiūrėti meniu ir užsisakyti',
      payBillCta: 'Apmokėti stalo sąskaitą',
      callWaiterCta: 'Kviesti padavėją',
      paymentsCta: 'Mokėjimai',
    },
    viewOrder: {
      orderNotFound: {
        title: 'Užsakymas nerastas',
        subtitle: 'Užsakymas nerastas. Pabandykite dar kartą.',
      },
    },
    newUserForm: {
      navTitle: 'Registracija',
      headerTitle: 'Pabaikite registraciją',
      headerSubtitle:
        'Užpildykite reikiamą informaciją ir susikurkite Greet Wallet.',
      firstNameLabel: 'Vardas Pavardė',
      firstNamePlaceholder: 'Vardenis Pavardenis',
      phoneNumberLabel: 'Telefono numeris',
      phoneNumberPlaceholder: '+37060000000',
      cityLabel: 'Miestas',
      cityPlaceholder: 'Vilnius',
      birthdayLabel: 'Gimimo data',
      birthdayPlaceholder: '1999-01-01',
      ctaLabel: 'Sukurti Greet Wallet',
    },
    loyaltyPreview: {
      navTitle: 'Registracija',
      title: 'Prisijunkite prie {restaurantName} lojalumo programos',
      subtitle:
        'Tapkite lojalumo programos nariais ir naudokitės siūlomomis nuolaidomis.',
      ctaAddToGreetWallet: 'Pridėti prie Greet Wallet',
      ctaCreateCardLabel: 'Gauti naują virtualią kortelę',
      ctaAddPhysicalCardLabel: 'Pridėti turimą fizinę kortelę',
      disclaimer:
        'Virtuali kortelė galioja tik užsakymams, pateiktiems telefonu per Greet platformą.',
    },
    enrollLoyalty: {
      navTitle: 'Registracija',
      title: 'Prisijunkite prie {restaurantName} lojalumo programos',
      subtitle:
        'Tapkite lojalumo programos nariais ir naudokitės siūlomomis nuolaidomis.',
      ctaLabel: 'Pridėti prie Greet Wallet',
    },
    loyaltyProgramDetails: {
      navTitle: 'Lojalumo programa',
      cardNumberLabel: 'Kortelės numeris',
      cardExpiryLabel: 'Galiojimo data',
    },
    donate: {
      empty: {
        title: 'Parama neaktyvi',
        subtitle:
          'Paramos gavėjas, kuriam ruošiatės palikti paramą, dar neaktyvavo savo Greet paskyros. Paskatinkite tai padaryti!',
      },
      donateTitle: 'Paramos suma',
      payCta: 'Apmokėti',
      transactionFee: 'Paslaugos mokestis',
      total: 'Iš viso',
      subtotal: 'Parama {name}',
    },
    donateSuccess: {
      title: 'dėkoja už tavo {amount} paramą!',
      failed: {
        title: 'Uuups! Kažkas atsitiko',
        subtitle:
          'Mokėjimas vis dar neįvykdytas. Pabandykite perkrauti puslapį.',
      },
    },
    paymentStatus: {
      loading: {
        title: 'Vykdomas mokėjimas',
        content:
          'Laukiame atsakymo iš jūsų banko. Neuždarykite šio lango, kol mokėjimas nebus patvirtintas.',
        longerThanExpected:
          'Mokėjimo apdorojimas trunka ilgiau nei įprastai. Taip gali nutikti dėl trikdžių bankuose. Neuždarykite šio lango, kol mokėjimas nebus patvirtintas.',
      },
      error: {
        title: 'Mokėjimas nepavyko',
        content:
          'Nepavyko gauti atsakymo iš jūsų banko. Jei lėšos buvo nuskaitytos, jos bus automatiškai grąžintos į jūsų banko sąskaitą. Norint susimokėti, pasirinkite kitą mokėjimo būdą arba kreipkitės į padavėją.',
        cta: 'Grįžti į užsakymą',
      },
      success: {
        title: 'Mokėjimas sėkmingas',
        content: 'Perkeliamas į užsakymo puslapį...',
      },
    },
  },
  components: {
    moreInformation: {
      search: 'Ieškoti',
      restaurantInformation: 'Informacija apie restoraną',
      translate: 'Pakeisti kalbą',
    },
    menuItem: {
      priceFrom: 'Nuo {price}',
      popular: 'Populiaru',
      choose: 'Rinktis',
      outOfStock: 'Laikinai neturime',
      addToOrder: 'Įdėti',
      remove: 'Išimti',
      chooseVariation: 'Pasirinkite:',
    },
    localeSelector: {
      automaticTranslations: {
        title: 'Pasirinkite kalbą',
        subtitle:
          'Užsienio kalbų vertimai yra automatiniai. Juose gali būti klaidų ar neatitikimų.',
      },
    },
    options: {
      required: 'Privaloma',
      can_choose_up_to_x_options: 'Galite pasirinkti iki {amount} priedų',
      can_choose_up_to_one_option: 'Galite pasirinkti 1 priedą',
      choose_up_to_x_options: 'Pasirinkite iki {amount} priedų',
      choose_up_to_one_option: 'Pasirinkite 1 priedą',
    },
    toast: {
      genericErrorTitle: 'Uuups! Kažkas atsitiko',
      genericErrorDescription: 'Įvyko klaida. Bandykite dar kartą.',
      orderSeenTitle: 'Užsakymas peržiūrimas &#128064;',
      orderSeenDescription:
        'Jūsų užsakymas gautas ir personalas tikslina pristatymo laiką.',
      orderAcceptedTitle: 'Užsakymas priimtas! &#10024;',
      orderAcceptedDescription: '{restaurantName} jau ruošia jūsų užsakymą!',
      orderItemDeliveredTitle: 'Dalis užsakymo pristatyta!',
      orderItemDeliveredDescription:
        'Atidarykite pranešimą ir sužinokite kokie produktai jau pristatyti.',
      orderFinishedTitle: 'Užsakymas pristatytas! &#9989;',
      orderFinishedDescription: 'Jau galite pasiimti savo užsakymą.',
      orderWaitTimeProlongedTitle: 'Atnaujintas pristatymo laikas! &#128357;',
      orderWaitTimeProlongedDescription:
        'Atidarykite pranešimą ir sužinokite naują pristatymo laiką.',
      orderFailedTitle: 'Užsakymas nepavyko. &#10060;',
      orderFailedDescription:
        'Užsakymo kūrimo metu įvyko klaida. Atidarykite užsakymą.',
      orderRejectedTitle: 'Užsakymas atšauktas. &#10060;',
      orderRejectedDescription:
        'Jūsų užsakymas buvo atšauktas. Atidarykite užsakymą norėdami sužinoti priežastį.',
      connectionOffTitle: '&#128245; Dingo jūsų interneto ryšys',
      connectionOffDescription: 'Pabandykite perkrauti puslapį',
      menuItemUnavailableTitle: 'Atsiprašome, šio produkto nebeturime...',
      menuItemUnavailableDescription:
        'Meniu pasikeitė ir produkto šiuo metu nebeturime, pekraukite puslapį.',
      menuItemNotEnoughStockTitle: 'Produkto likutis nepakankamas',
      menuItemNotEnoughStockDescription:
        'Negalima užsakyti pasirinkto produkto kiekio. Šio produkto likutis: {leftInStock}',
      placeOrderItemsUnavailableTitle:
        'Atsiprašome, nebeturime kai kurių produktų...',
      placeOrderItemsUnavailableDescription:
        'Meniu pasikeitė ir kai kurių jūsų užsakomų produktų nebeturime, perkraukite puslapį.',
      restaurantNotAcceptingOrdersTitle: 'Restoranas uždarytas',
      restaurantNotAcceptingOrdersDescription:
        'Šiuo metu restoranas nepriima užsakymų per Greet platformą.',
      couponNotFoundTitle: 'Kuponas nebegalioja',
      couponNotFoundDescription:
        'Kuponą, kurį įvedėte, neradome arba jis baigė galioti. Patikrinkite ar įvedėte teisingą kodą.',
      totalTooSmallTitle: 'Krepšelio suma per maža',
      totalTooSmallDescription:
        'Jūsų užsakymo suma per maža, minimali suma yra {amount}.',
      rushMode: {
        title: 'Ilgesnis užsakymo gaminimo laikas',
        description:
          'Šiuo metu užsakymus pagaminame vidutiniškai per {time} min.',
      },
      tableTakenTitle: 'Staliukas užimtas',
      tableTakenDescription:
        'Staliuko užsakymą jau priėmė padavėjas, deja negalime priimti užsakymo.',
      cardAlreadyExistsTitle: 'Kortelės pridėti nepavyko',
      cardAlreadyExistsDescription:
        'Ši kortelė jau pridėta prie Greet Wallet. Pabandykite pridėti kitą kortelę.',
      cardNotValidTitle: 'Nuolaidų kortelės pridėti nepavyko',
      cardNotValidDescription:
        'Įvesta nuolaidų kortelė netinkama. Pabandykite pridėti kitą kortelę.',
      terminalIsOfflineTitle: 'Kasos terminalas nepasiekiamas',
      terminalIsOfflineDescription:
        'Užsakymo pateikti negalite, nes kasos terminalas yra nepasiekiamas. Kreipkitės į aptarnaujantį personalą arba pabandykite vėliau.',
      discountAppliedToastTitle: 'Sąskaitos padalijimas negalimas! 🚫',
      discountAppliedToastDescription:
        'Pritaikius nuolaidą, mokėti už pasirinktus produktus negalima. Pasirinkite mokėjimą už visą užsakymą.',
      itemSelectedByOtherToastTitle: 'Produkto pasirinkti negalima! ⚠️',
      itemSelectedByOtherToastDescription:
        'Norimas produktas jau pasirinktas kito svečio.',
    },
    review: {
      thankYouMessage: 'Tavo įvertinimas buvo perduotas.',
      commentPlaceholder: 'Komentaras',
      ctaLabel: 'Palikti atsiliepimą',
      thankYouTitle: 'Ačiū!',
      title: 'Įvertinkite apsilankymą',
      tagsLabel: 'Patikslinkite',
      leaveGoogleReviewCtaLabel: 'Palikti atsiliepimą Google',
      leaveGoogleReviewContent:
        'Būtume dėkingi, jei paliktumėte atsiliepimą Google.',
      leaveReviewCta: 'Įvertinkite apsilankymą',
    },
    moneyInput: {
      placeholder: '0',
      validationMessage: 'Įveskite sumą nuo {min} iki {max}',
    },
    notificationModal: {
      countrySelectorLabel: 'Šalis',
      countrySelectorError: 'Choisir un pays',
      phoneNumberLabel: 'Telefono numeris',
      example: 'pvz.: 600 00000',
    },
    paymentMethod: {
      noPaymentProvidersMessage:
        'Šiuo metu nepriimame jokių mokėjimų. Pabandykite vėliau.',
      prButtonUpperDivider: 'Greitas apmokėjimas',
      prButtonLowerDivider: 'arba',
      cardTitle: 'Kreditinė / debetinė kortelė',
      cardDescription: 'Mastercard, Visa, American Express ir kt.',
      montonioTitle: 'El. bankininkystė',
      montonioSubtitle:
        'Paspaudę „Apmokėti“ galėsite pasirinkti savo el. bankininkystės sistemą ir saugiai užbaigti pirkimą kitame lange.',
      banklinkTitle: 'El. bankininkystė',
      banklinkDescription: 'Swedbank, SEB, Luminor ir kt.',
      banklinkDisclaimer:
        'Neuždarinėkite banko puslapio atlikus mokėjimą. Palaukite, kol automatiškai būsite grąžinti atgal į užsakymo langą.',
      blikTitle: 'BLIK',
      blikDescription: 'Mokėjimas su BLIK kodu',
      blikSubtitle:
        'Paspaudę „Apmokėti“ būsite nukreipti į BLIK ir galėsite saugiai užbaigti pirkimą kitame lange.',
      cashTitle: 'Apmokėti grynaisiais',
      cashDescription: 'Apmokėkite užsakymą atsiimant grynaisiais',
    },
    discountCode: {
      title: 'Įvesti nuolaidos kupono kodą',
      codeInputPlaceholder: 'Jūsų nuolaidos kupono kodas',
      ctaLabel: 'Taikyti nuolaidą',
      successTitle: 'pritaikyta',
      successAmount: 'Sutaupote {discountAmountFormatted}',
      successPayAtRestaurant:
        'Naudojant šį nuolaidos kodą sąskaita apmokama pas padavėją.',
      couponNotFound: 'Įvestas nuolaidos kodas negalioja',
      couponExceedsLimit: 'Nuolaida negali būti didesnė nei užsakymo suma.',
    },
    cartSubtotal: {
      orderValue: 'Užsakymo suma',
      tips: 'Arbatpinigiai',
      grandTotal: 'Iš viso',
      leftToPay: 'Liko mokėti',
    },
    tipPersonnel: {
      title: 'Padėkokite padavėjui!',
      subtitle:
        'Paspausdami ant nuotraukos pasirinkite, kam norėtumėte palikti arbatpinigių.',
      personnelSubtitle: 'Gaus 100 % Jūsų pasirinktos sumos.',
      pickAmountTitle: 'Pasirinkite arbatpinigių sumą',
      minTipAmountDisclaimer:
        'Restoranas taiko minimalų arbatpinigių dydį, kai staliuko svečių skaičius viršija {guestCount}.',
      collective: {
        subtitle:
          'Padavėjas, aptarnaujantis jūsų stalą, gaus 100 % pasirinktos sumos.',
      },
    },
    personnelPicker: {
      ctaLabel: 'Palikti arbatpinigių',
    },
    loyaltyTOS: {
      content:
        'Sutinku, kad mano duomenys būtų perduodami valdyti UAB Čili pica bei tvarkomi pagal Čili Pica',
      terms: 'Privatumo taisykles',
    },
    firebaseLogin: {
      signInWithGoogle: 'Tęsti su Google',
      signInWithFacebook: 'Tęsti su Facebook',
      signInWithEmail: 'Prisijunkite',
      registerWithEmail: 'Registruotis su el. paštu',
      emailLabel: 'El. paštas',
      passwordLabel: 'Slaptažodis',
      passwordConfirmationLabel: 'Pakartokite slaptažodį',
      singIn: 'Prisijungti',
      register: 'Registruotis',
      tosTitle: 'Prisiregistruodami patvirtinate, kad susipažinote su',
      errorAccountExists:
        'Paskyra su tokiu el. paštu jau egzistuoja. Pabandykite kitą prisijungimo būdą.',
      errorGeneric: 'Įvyko klaida. Pabandykite vėliau.',
      errorWrongPassword: 'Neteisingas slaptažodis.',
      errorUserNotFound: 'Paskyra su tokiu el. paštu neegzistuoja.',
      errorInvalidEmail: 'Neteisingas el. pašto adresas.',
      errorEmailAlreadyInUse: 'Paskyra su tokiu el. paštu jau egzistuoja.',
      errorWeakPassword: 'Slaptažodis per silpnas.',
      errorPasswordsDoNotMatch: 'Slaptažodžiai nesutampa.',
      errorPopupClosedByUser: 'Prisijungimas atšauktas.',
      doNotHaveAccount: 'Neturite paskyros?',
      signUp: 'Registruokitės',
      alreadyHaveAccount: 'Jau turite paskyrą?',
    },
    onboarding: {
      orders: {
        step1: {
          title: 'Išsirinkite patiekalą ar gėrimą',
          description:
            'Paspauskite ant norimo patiekalo ar gėrimo ir pridėkite jį prie užsakymo.',
        },
        step2: {
          title: 'Atsiskaitykite greitai ir patogiai',
          description:
            'Pasirinkite Jums patogiausią atsiskaitymo būdą ir apmokėkite nelaukdami padavėjo.',
        },
        step3: {
          title: 'Įvertinkite užsakymą',
          description: 'Apsilankymo pabaigoje įvertinkite užsakymo kokybę ',
        },
      },
      foodCourt: {
        step1: {
          title: 'Labas! Su tavimi sveikinasi Greet',
          description:
            'Susipažink su paprasčiausiu būdu pateikti ir apmokėti užsakymą. Trumpai paaiškinsime, kaip visa tai veikia.',
        },
        step2: {
          title: 'Pasirink restoraną',
          description: 'Iš pateikto sąrašo išsirink norimą restoraną.',
        },
        step3: {
          title: 'Pateik užsakymą',
          description:
            'Atsidaręs restorano meniu, sudaryk savo norimą užsakymą ir jį apmokėk.',
        },
        step4: {
          title: 'Atsiimk gavęs pranešimą',
          description:
            'Pateikęs užsakymą, stebėk užsakymo eigą ir atsiimk gavęs pranešimą į savo telefoną. Skanaus!',
        },
      },
      payGo: {
        step1: {
          title: 'Labas! Su tavimi sveikinasi Greet',
          description:
            'Susipažink su paprasčiausiu būdu apmokėti stalo sąskaitą. Trumpai paaiškinsime, kaip visa tai veikia.',
        },
        step2: {
          title: 'Stebėk užsakymą telefone',
          description:
            'Padavėjui priėmus užsakymą, jis atsiras telefono ekrane.',
        },
        step3: {
          title: 'Skaidyk ir apmokėk sąskaitą',
          description:
            'Pasirinkęs norimus produktus, juos galėsi apmokėti patogiausiu būdu.',
        },
        step4: {
          title: 'Būk ramus dėl apmokėjimo',
          description:
            'Personalas bus informuotas iškart, kai tik apmokėsi sąskaitą.',
        },
      },
    },
    approveDrinkingAge: {
      title:
        'Alkoholinius gėrimus gali įsigyti tik asmenys, kuriems yra ne mažiau kaip 20 metų.',
      cta: 'Man yra 20 metų',
    },
    requestInvoice: {
      button: 'Gauti PVM sąskaitą faktūrą',
      title: 'PVM sąskaitos faktūros gavimas',
      form: {
        title: 'Užpildykite formą',
        subtitle:
          'Pateikite imonės informaciją ir restoranas Jums atsiųs išrašytą PVM sąskaitą faktūrą.',
        namePlaceholder: 'Vardas ir pavardė',
        businessNamePlaceholder: 'Įmonės pavadinimas',
        businessCodePlaceholder: 'Įmonės kodas',
        businessAddressPlaceholder: 'Įmonės adresas',
        countryPlaceholder: 'Valstybė',
        emailPlaceholder: 'Kontaktinis el. pašto adresas',
        vatCodeQuestion: 'Ar juridinis asmuo yra PVM mokėtojas?',
        vatCodePlaceholder: 'PVM mokėtojo kodas',
        submitButton: 'Pateikti',
      },
      success: {
        title: 'Pateikta sėkmingai',
        subtitle:
          'Atsiųsime Jums išrašytą PVM sąskaitą faktūrą nurodytu el.pašto adresu netrukus.',
        cta: 'Grįžti į meniu',
      },
      error: {
        cannotUpdateInvoice:
          'Sąskaita-faktūra jau pateikta, tad duomenys nebegali būti keičiami.',
      },
    },
    requestReceiptButton: {
      cta: 'Gauti kvitą el. paštu',
      modal: {
        title: 'Gauti kvitą el. paštu',
        emailLabel: 'El. pašto adresas',
        submitButton: 'Pateikti',
        emailError: 'Įveskite teisingą el. pašto adresą',
      },
      successToast: {
        title: 'Mokėjimo kvitas jau pakeliui',
        description: 'Mokėjimo kvitas išsiųstas nurodytu el. pašto adresu.',
      },
    },
    callWaiter: {
      toast: {
        title: 'Padavėjui buvo pranešta',
        description: 'Aptarnaujantis personalas greitu metu pas jus užsuks.',
      },
    },
    sortableCart: {
      title: 'Užsakymas',
      cta: 'Keisti eiliškumą',
      ctaEnd: 'Baigti',
      deliverASAPLabel: 'Gauti visus patiekalus iškart',
      dragHereLabel: 'Vilkite patiekalus čia',
      modal: {
        title: 'Keiskite patiekalų eiliškumą',
        subtitle:
          'Paspauskite ir vilkite produktą, taip valdydami patiekalų paruošimo eiliškumą.',
      },
    },
    pisWarning: {
      title: 'Dėmesio!',
      content:
        'Neuždarinėkite banko puslapio atlikus mokėjimą.\n\nPalaukite, kol automatiškai būsite grąžinti atgal į Greet langą ir mokėjimas bus patvirtintas.',
      cta: 'Tęsti',
    },
    paymentBottomSheetError: {
      generic: {
        title: 'Bankas atmetė jūsų mokėjimą',
        content:
          'Mokėjimui pasirinkite kitą mokėjimo būdą ir bandykite dar kartą.',
        cta: 'Keisti mokėjimo metodą',
      },
      insufficientFunds: {
        title: 'Kortelėje nepakanka lėšų',
        content:
          'Mokėjimas nepavyko dėl nepakankamų lėšų kortelėje. Pasirinkite kitą mokėjimo būdą ir bandykite dar kartą',
        cta: 'Keisti mokėjimo metodą',
      },
    },
  },
  generic: {
    menu: 'Meniu',
    new: 'Nauja',
    cancel: 'Atšaukti',
    menuUpdating: 'Šiuo metu vyksta meniu atnaujinimas. Pabandykite vėliau.',
    somethingWentWrong:
      'Atsiprašome, atrodo, jog įvyko klaida. Pabandykite pekrauti puslapį.',
    servicesAreDisabled: 'Paslauga yra sustabdyta',
    reloadButton: 'Perkrauti',
    and: 'ir',
    save: 'Išsaugoti',
    enable: 'Įjungti',
    notNow: 'Ne dabar',
    serverErrorTitle: 'Uuups! Kažkas atsitiko',
    serverErrorDescription:
      'Atrodo, jog įvyko klaida. Pabandykite pekrauti puslapį.',
    or: 'arba',
    requiredField: 'Laukas yra privalomas',
    invalidPhone: 'Įveskite tinkamą telefono numerį (pvz. +37060000000)',
    invalidEmail: 'Įveskite tinkamą el. pašto adresą',
    next: 'Toliau',
    understood: 'Supratau',
    easyWith: 'paprasta su',
    invalidBirthDate: 'Įveskite tinkamą gimimo datą (pvz. 1999-01-01)',
    close: 'Uždaryti',
    ok: 'OK',
    unavailable: 'Nepasiekiamas',
  },
  Vegetarian: 'Vegetariška',
  Vegan: 'Veganiška',
  'Mild Spicy': 'Lengvai aštru',
  'Medium Spicy': 'Vidutiniškai aštru',
  'Hot Spicy': 'Labai aštru',
  'Special Deals': 'Specialūs pasiūlymai',
  Savory: 'Pikantiškas',
  New: 'Nauja',
  Pescetarian: 'Pesketariška',
  Taste: 'Skonis',
  Service: 'Aptarnavimas',
  Speed: 'Greitis',
  Atmosphere: 'Atmosfera',
  Staff: 'Personalas',
  Terrible: 'Siaubinga',
  Bad: 'Blogai',
  Meh: 'Meh',
  Good: 'Gerai',
  Excellent: 'Puikiai',
  required: 'Privaloma',
  // FOOD COURT
  foodCourt: {
    restaurantList: {
      cta: 'Aktyvūs užsakymai',
      closed: 'Uždaryta',
    },
  },
  kovo11: '🇱🇹 Su Kovo 11-ąja! Greet komanda.',
  kiosk: {
    terminalCommands: {
      title: 'Ataskaitos',
      x: {
        title: 'X ataskaita',
        description:
          'X-Ataskaita yra ataskaita, kuri pateikia informaciją apie transakcijas, kurios buvo apdorotos terminalu nuo paskutinės Z-Ataskaitos. Ji naudojama patikrinti transakcijas, kurios buvo apdorotos terminalu.',
        cta: 'Išspausdinti X ataskaitą',
      },
      z: {
        title: 'Z ataskaita',
        description:
          'Z-Ataskaita yra ataskaita, kuri pateikia informaciją apie transakcijas, kurios buvo apdorotos terminalu nuo paskutinės Z-Ataskaitos. Spustelėję mygtuką žemiau, uždarysite dieną ir išspausdinsite Z-Ataskaitą.',
        cta: 'Išspausdinti Z ataskaitą',
      },
      printerError:
        'Nepavyko prisijungti prie spausdintuvo. Prašome patikrinti spausdintuvą ir bandyti dar kartą.',
      connections: {
        title: 'Įrenginių būsena',
        cardReaderLabel: 'Kortelių skaitytuvas',
        printerLabel: 'Spausdintuvas',
        testPrinterLabel: 'Spausdintuvo testas',
        print: 'Spausdinti',
      },
      unresolvedCRNs: {
        title: 'Dėmesio!',
        description:
          'Terminalas turi nepasibaigusių transakcijų. Prašome palaukti kol transakcijos bus apdorotos.',
        cardTitle: 'Neužbaigtos transakcijos ({count})',
        cardDescription:
          'Jūsų terminalas turi neužbaigtų transakcijų. Norint užbaigti transakcijas rankiniu būdu, spustelėkite mygtuką apačioje.',
        cta: 'Užbaigti transakcijas',
      },
    },
    payInPerson: {
      subtitle: 'Mokėtina jūsų užsakymo suma',
      terminal: {
        followInstructions: 'Sekite instrukcijas terminalo ekrane',
        completingOrder: 'Užsakymas vykdomas',
        voidSuccess: 'Mokėjimas atšauktas',
        sendingToPOS: 'Užsakymas siunčiamas į kasą',
        forceSettle: 'Siunčiama atasakaita, prašome palaukti',
      },
      payCTA: 'Mokėti kortele',
      dismissCTA: 'Atšaukti',
    },
    error: {
      defaultCtaLabel: 'Supratau',
      retryCtaLabel: 'Bandyti mokėti dar kartą',
      startOverCtaLabel: 'Pradėti užsakymą iš naujo',
      bakErrorLabel: 'Banko klaida: {text} ({code})',
      GENERIC: {
        title: 'Klaida',
        message: 'Įvyko klaida. Prašome bandyti dar kartą.',
      },
      ORDER_CREATE_FAILED: {
        title: 'Užsakymo sukurti nepavyko',
        message:
          'Įvyko klaida kuriant užsakymą. Pinigai bus grąžinti. Kreipkitės į aptarnaujantį personalą.',
        ctaLabel: 'Pradėti iš naujo',
      },
      UC: {
        title: 'Mokėjimas atšauktas',
        message: 'Mokėjimas buvo atšauktas vartojo. Galite bandyti dar kartą.',
      },
      TA: {
        title: 'Transakcija atšaukta',
        message: 'Mokėjimas atšauktas. Galite bandyti dar kartą.',
      },
      BS: {
        title: 'Kortelių skaitytuvas užimtas',
        message:
          'Šiuo metu kortelių skaitytuvas atlieka kitą operaciją. Pabandykite vėliau.',
      },
      NR: {
        title: 'Kortelių skaitytuvas nepasiekiamas',
        message:
          'Kortelių skaitytuvas šiuo metu yra nepasiekiamas. Prašome kreiptis į aptarnaujantį personalą.',
      },
      NP: {
        title: 'Nerasta kortelių skaitytuvo',
        message:
          'Kortelių skaitytuvas nerastas. Prašome kreiptis į aptarnaujantį personalą.',
      },
      '116': {
        title: 'Kortelėje nepakanka lėšų',
        message:
          'Mokėjimas nepavyko dėl nepakankamų lėšų kortelėje. Pasirinkite kitą mokėjimo būdą ir bandykite dar kartą.',
      },
    },
    success: {
      title: 'Užsakymas #{orderId} gautas!',
      content: 'Paruoštą užsakymą atsiimkite prie baro.',
      cta: 'Naujas užsakymas',
      secondCTA: 'Gauti mokėjimo kvitą',
    },
    printer: {
      yourOrder: 'Jūsų užsakymo numeris',
      orderFailed: 'Užsakymo sukurti nepavyko',
      couponCharge: 'Apmokėta naudojant nuolaidos kodą',
      scanQR: 'Sekite užsakymą skenuodami QR kodą',
      additionalFields: 'Papildoma informacija',
    },
    toasts: {
      printerCoverOpen: {
        title: 'Spausdintuvo dangtelis atidarytas',
        description: 'Uždarykite spausdintuvo dangtelį ir bandykite dar kartą.',
      },
      printerAlmostOutOfPaper: {
        title: 'Netrukus baigsis popierius',
        description: 'Pakeiskite spausdinimo popierių ir bandykite dar kartą.',
      },
      printerOutOfPaper: {
        title: 'Baigėsi popierius',
        description: 'Pakeiskite spausdinimo popierių ir bandykite dar kartą.',
      },
      terminalConnectionError: {
        title: 'Terminalo ryšio klaida',
        description:
          'Kortelių skaitytuvas nerastas. Prašome kreiptis į aptarnaujantį personalą.',
      },
    },
  },
};
