<template>
  <div class="pay-container">
    <v-btn
      v-if="showResetButton"
      class="close-button"
      dense
      text
      icon
      @click="startNewSession"
    >
      <v-progress-circular
        v-if="resetting"
        color="black"
        size="20"
        width="2"
        indeterminate
      />
      <v-icon v-else color="black" dense>mdi-replay</v-icon>
    </v-btn>
    <div v-if="loading">
      <v-progress-circular indeterminate />
    </div>
    <div v-else class="pay-content-container">
      <div class="pay-card" data-cy="pay-in-person-screen">
        <div class="icon-container">
          <div class="icon-content-container">
            <v-img src="/assets/icons/bill.svg" max-width="50px" />
          </div>
        </div>
        <div class="my-10 text-center">
          <InPersonPaymentStatus
            ref="status"
            :dialog-text="dialogText"
            @abort="handleAbortTransaction"
          />
        </div>
      </div>
      <InPersonPaymentErrorBottomSheet
        ref="errorBottomSheet"
        @retry="pay"
        @startOver="startNewSession"
      />
    </div>
  </div>
</template>

<script>
import InPersonPaymentStatus from '@/components/Payments/InPersonPaymentStatus.vue';
import InPersonPaymentErrorBottomSheet from '@/components/Payments/InPersonPaymentErrorBottomSheet.vue';
import { OPERATION, generateCRN } from '@/utils/worldLine';

const PAYMENT_STATUSES = {
  idle: 'idle',
  processing: 'processing',
  error: 'error',
  success: 'success',
  disconnected: 'disconnected',
  void: 'void',
};

export default {
  components: {
    InPersonPaymentStatus,
    InPersonPaymentErrorBottomSheet,
  },
  data() {
    return {
      loading: false,
      posStatus: PAYMENT_STATUSES.idle,
      connection: null,
      error: null,
      paymentIntent: {},
      dialogText: null,
      uniqueOrderNumber: null,
      printer: null,
      cardholderReceipt: null,
      printerInterval: null,
      abortedByUser: false,
      resetting: false,
      showResetButton: false,
    };
  },
  computed: {
    terminalOnline() {
      return this.$store.getters.terminalOnline;
    },
    printerOnline() {
      return this.$store.getters.printerIsOnline;
    },
    payButtonDisabled() {
      return (
        !this.printerOnline ||
        // !this.terminalOnline ||
        this.posStatus === PAYMENT_STATUSES.void ||
        this.posStatus === PAYMENT_STATUSES.processing
      );
    },
    order() {
      const { getOrderByUniqueOrderNumber } = this.$store.getters;
      return getOrderByUniqueOrderNumber(
        this.paymentIntent?.unique_order_number
      );
    },
    unresolvedCRNs() {
      return this.$store.getters.unresolvedCRNs;
    },
    formattedTotalAmount() {
      return this.$store.getters.getFormattedPrice(this.paymentIntent.total);
    },
  },
  watch: {
    async order(newOrder) {
      if (!newOrder || this.posStatus !== PAYMENT_STATUSES.processing) {
        return;
      }

      const sendingToPOS = newOrder.sending_to_pos;
      const orderSentToPOS = newOrder.sent_to_pos;
      const orderFailed = newOrder.status === 'failed';

      if (orderFailed) {
        this.posStatus = PAYMENT_STATUSES.error;
        this.$refs?.status?.hide();
        this.$refs?.errorBottomSheet?.open('ORDER_CREATE_FAILED');
        this.printReceipt(this.cardholderReceipt);
        this.voidPayment();
        return;
      } else if (sendingToPOS && orderSentToPOS) {
        const sessionToken = this.$route.params.session;
        const baseUrl = process.env.VUE_APP_BASE_URL.includes('localhost')
          ? 'http://app-stage.greet.menu'
          : process.env.VUE_APP_BASE_URL;
        const QRLink = `${baseUrl}/view-kiosk-order/${sessionToken}/${newOrder.unique_order_number}?force=1`;
        this.printReceipt(
          this.cardholderReceipt,
          this.order.order_id,
          newOrder.additional_fields,
          QRLink
        );
        this.goToPaymentSuccess(this.paymentIntent.uuid);
      } else if (sendingToPOS) {
        this.dialogText = [this.$t('kiosk.payInPerson.terminal.sendingToPOS')];
      }
    },
    terminalOnline(newStatus, oldStatus) {
      if (newStatus && !oldStatus) {
        this.reconnectToCardReader();
      } else if (!newStatus) {
        this.posStatus = PAYMENT_STATUSES.idle;
        this.$refs?.status?.hide();
        this.$refs?.errorBottomSheet?.open('NP');
      }
    },
  },
  async mounted() {
    this.loading = true;
    const sessionToken = this.$route.params.session;

    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
    await this.$store.dispatch('subscribeToPosTerminal');
    this.setTerminalMessageCallback();
    this.$store.dispatch('fetchOrders');
    this.$store.dispatch('subscribeToPusherChannels');

    this.pay();
  },
  methods: {
    goBack() {
      this.handleAbortTransaction();
      this.$router.go(-1);
    },
    startNewSession() {
      this.resetting = true;
      this.$store.dispatch('generateNewKioskSession');
    },
    async pay() {
      // Clear unresolved CRNs before sending new payment request
      await this.$store.dispatch('voidUnresolvedCRNs');
      await this.generatePaymentIntent(false);
      this.loading = false;
      this.showResetButton = false;
      this.abortedByUser = false;

      this.posStatus = PAYMENT_STATUSES.processing;
      this.dialogText = [
        this.$t('kiosk.payInPerson.terminal.followInstructions'),
      ];
      this.$refs.status.show({
        status: 'loading',
        showCancel: true,
      });

      const CRN = this.paymentIntent.CRN;
      const data = {
        ecrNameVersion: this.paymentIntent.ecr_name_version,
        receiptLineLength: this.paymentIntent.receipt_line_length,
        CRN,
        dlgDisabled: false,
        operation: OPERATION.SALE,
        amount: Math.round(this.paymentIntent.total * 100),
      };

      const onError = () => {
        this.posStatus = PAYMENT_STATUSES.idle;
        this.$refs?.status?.hide();
      };

      this.$store.dispatch('addUnresolvedCRN', { CRN });
      await this.$store.dispatch('sendPosTerminalMessage', { data, onError });
    },
    goToPaymentSuccess(paymentUUID = null) {
      this.$router.push({
        name: 'PayInPersonSuccess',
        query: {
          orderNumber: this.$route.params.order,
        },
        params: {
          session: this.$store.state.session,
          paymentUUID,
        },
      });
    },
    async handleCompleteSale(data) {
      // if transaction was aborted by user from our side after correct PIN entry.
      if (this.abortedByUser) {
        console.warn('Transaction was aborted by user. Voiding payment.');
        // this.dialogText = [];
        this.printReceipt(data.cardholderReceipt);
        setTimeout(() => {
          this.voidPayment(data.CRN);
          this.posStatus = PAYMENT_STATUSES.idle;
          this.$refs?.status?.hide();
          this.abortedByUser = false;
          this.$refs?.errorBottomSheet?.open('UC');
        }, 2000);
        return;
      }

      this.posStatus = PAYMENT_STATUSES.processing;
      this.$refs.status.show({
        status: 'loadingGeneric',
        showCancel: false,
      });
      this.dialogText = [this.$t('kiosk.payInPerson.terminal.completingOrder')];

      const CRN = this.paymentIntent.CRN;
      await this.$store.dispatch('updateInPersonPaymentIntent', {
        type: 'payment_succeeded',
        data,
      });

      this.$store.dispatch('removeUnresolvedCRN', { CRN });
      this.cardholderReceipt = data.cardholderReceipt;
    },
    voidPayment(CRN = this.paymentIntent.CRN) {
      const data = {
        CRN,
        operation: OPERATION.VOID,
        reversalType: 'autoVoid',
        dlgDisabled: false,
        receiptLineLength: this.paymentIntent.receipt_line_length,
      };

      this.$store.dispatch('sendPosTerminalMessage', { data });
    },
    async handleForceSettle() {
      this.$store.dispatch('removeUnresolvedCRN', {
        CRN: this.paymentIntent.CRN,
      });
      this.posStatus = PAYMENT_STATUSES.processing;
      this.$refs.status.show({
        status: 'loadingGeneric',
        showCancel: false,
      });
      this.dialogText = [this.$t('kiosk.payInPerson.terminal.forceSettle')];
      const CRN = generateCRN();

      // wait for a while to ensure the terminal is ready to accept new commands
      return new Promise(resolve => {
        setTimeout(() => {
          const data = {
            operation: OPERATION.SETTLEMENT,
            CRN,
            ecrNameVersion: this.paymentIntent.ecr_name_version,
            receiptLineLength: this.paymentIntent.receipt_line_length,
            dlgDisabled: false,
          };

          this.$store.dispatch('sendPosTerminalMessage', { data });
          this.posStatus = PAYMENT_STATUSES.idle;
          resolve();
        }, 2000);
      });
    },
    handleAbortTransaction() {
      this.abortedByUser = true;
      // this.dialogText = [];

      const CRN = this.paymentIntent.CRN;
      const data = { CRN, operation: OPERATION.ABORT };

      this.$store.dispatch('sendPosTerminalMessage', { data });
      this.$refs.status.show({
        status: 'loadingGeneric',
        showCancel: false,
        CRN,
      });

      // if it takes too long to abort the transaction, show the reset button
      setTimeout(() => {
        const stillOpen = this.$refs?.status?.getIsOpenForCRN(CRN);
        if (stillOpen) {
          this.showResetButton = true;
        }
      }, 15000);
    },
    setTerminalMessageCallback() {
      const onmessage = async message => {
        const data = JSON.parse(message.data);
        this.error = null;

        if (data.operation === OPERATION.DIALOG) {
          console.log('TERMINAL: Dialog message received', data);
        } else if (data.result && data.operation === OPERATION.SALE) {
          await this.handleCompleteSale(data);
        } else if (
          !data.result &&
          data.responseCode === 'SR' &&
          data.operation === OPERATION.SALE
        ) {
          await this.handleForceSettle();
          setTimeout(() => {
            this.pay();
          }, 1000);
          return;
        } else if (data.result && data.operation === OPERATION.SETTLEMENT) {
          this.printReceipt(data.merchantReceipt);
          this.$store.dispatch('saveSettlement', {
            data,
          });
          this.$store.dispatch('removeUnresolvedCRN', { CRN: data.CRN });

          const order = this.$route.params.order;
          const paymentIntent = await this.$store.dispatch(
            'generateInPersonPaymentIntent',
            { order }
          );
          this.$refs?.status?.hide();
          this.paymentIntent = paymentIntent;
          return;
        } else if (data.operation === OPERATION.VOID && data.result) {
          this.posStatus === PAYMENT_STATUSES.void;
          this.dialogText = [this.$t('kiosk.payInPerson.terminal.voidSuccess')];
          this.printReceipt(data.cardholderReceipt);

          this.$store.dispatch('removeUnresolvedCRN', { CRN: data.CRN });

          await this.$store.dispatch('updateInPersonPaymentIntent', {
            type: 'payment_voided',
            data,
          });

          const order = this.$route.params.order;
          const paymentIntent = await this.$store.dispatch(
            'generateInPersonPaymentIntent',
            { order }
          );

          this.paymentIntent = paymentIntent;
          return;
        } else if (
          data.operation === OPERATION.VOID &&
          !data.result &&
          data.responseCode === 'NF'
        ) {
          console.error('TERMINAL: CRN was not found, nothing to auto-void.');
          this.$store.dispatch('removeUnresolvedCRN', { CRN: data.CRN });
        } else if (!data.result) {
          const CRN = this.paymentIntent.CRN;
          this.error = data.responseText;
          this.posStatus = PAYMENT_STATUSES.error;
          this.$refs?.status?.hide();

          // mute 1xx error codes for now
          // const match1xxError = data.responseCode.match(/1\d{2}/); // 1xx error codes
          // if (match1xxError) {
          //   this.printReceipt(
          //     data.cardholderReceipt || data.merchantReceipt || CRN
          //   );
          // }

          if (data.cardholderReceipt) {
            this.printReceipt(data.cardholderReceipt);
          }

          this.$refs.errorBottomSheet?.open(
            data.responseCode,
            CRN,
            data.responseText
          );
        } else {
          this.dialogText = [
            this.$t('kiosk.payInPerson.terminal.unknownError'),
          ];
          this.posStatus = PAYMENT_STATUSES.idle;
          this.$refs?.status?.hide();
        }
      };
      this.$store.dispatch('setOnMessageCallback', { callback: onmessage });
    },
    async generatePaymentIntent(withLoader = true) {
      const order = this.$route.params.order;
      if (withLoader) {
        this.loading = true;
      }
      const paymentIntent = await this.$store.dispatch(
        'generateInPersonPaymentIntent',
        { order }
      );

      this.paymentIntent = paymentIntent;
      this.paymentIntent.receipt_line_length = 48;
      this.loading = false;
    },
    printReceipt(text, orderId = null, additionalFields, QRLink) {
      this.$store.dispatch('printReceipt', {
        text,
        orderId,
        additionalFields,
        QRLink,
      });
    },
    async reconnectToCardReader() {
      await this.$store.dispatch('subscribeToPosTerminal', { force: true });
      this.setTerminalMessageCallback();
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-container {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  background-size: 400% 400%;
}

.pay-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  padding: 40px;
}

.icon-container {
  margin-top: -100px;
}

.icon-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.pay-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
